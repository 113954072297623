const postInfo = [
  {
    id: 2,
    date: "aug 07, 2020",
    title: "a review of my mesh wifi system but written like a pitchfork review",
    tags: ["humor", "tech"]
  },
  {
    id: 1,
    date: "mar 22, 2020",
    title: "can friction in a system ever be useful?",
    tags: ["tech", "design"]
  },
];

exports.info = postInfo;